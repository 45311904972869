import { useEffect, useRef, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { User } from '@supabase/auth-helpers-react';
import A11yDialogInstance from 'a11y-dialog';
import classNames from 'classnames';

import { Cart } from '@components/cart/Cart';
import { MembershipAnchor } from '@components/membershipAnchor/MembershipAnchor';
import { Svg } from '@components/svg/Svg';

import { getSubscriptionMetadata } from '@common/utils/membership';
import { getIsActiveUrl } from '@common/utils/navigation';
import { useCart } from '@context/CartContext';
import { useUser } from '@context/UserContext';
import { MY_EVENTS_URL } from '@data/constants';
import { SupabaseSubscriptionRecordWithPrices } from '@interfaces';

import styles from './navigation.module.scss';
import { NavigationAddOn } from './NavigationAddOn';
import { NavigationAnchor } from './NavigationAnchor';
import { NavigationFullscreenOpenButtonContent } from './NavigationFullscreenOpenButtonContent';

const membershipLink = {
	url: '/membership',
	title: 'Membership',
};

const myEventsLink = {
	url: MY_EVENTS_URL,
	title: 'My events',
};

const getNavigationLinks = (
	authUser: User | null,
	subscription?: SupabaseSubscriptionRecordWithPrices | null,
) => {
	const { tier_level: tierLevel } = getSubscriptionMetadata(subscription);

	const links = [
		{
			url: '/events',
			title: 'Calendar',
		},
		{
			url: '/rentals',
			title: 'Private events',
		},
	];

	if (!authUser) {
		return links;
	}

	if (subscription && tierLevel !== '1') {
		links.push(myEventsLink);
	}

	return links;
};

type NavigationProps = {
	navigationFullscreenInstance?: A11yDialogInstance;
};

let scrollTimeout: NodeJS.Timeout;

export const Navigation = ({
	navigationFullscreenInstance,
}: NavigationProps) => {
	const router = useRouter();
	const { authUser, subscription } = useUser();
	const { cart } = useCart();

	const [showMobileHeader, setShowMobileHeader] = useState(true);
	const [lastScrollY, setLastScrollY] = useState(0);

	const navigationContainer = useRef<HTMLDivElement>(null);
	const navigationMobileHeader = useRef<HTMLDivElement>(null);

	const navigationLinks = getNavigationLinks(authUser, subscription);
	const visibleOnMobile = ['Calendar', 'Zine', 'Shop'];
	const visibleOnSmallDesktopIfNotLoggedIn = ['Membership', 'Calendar'];
	const visibleOnSmallDesktopIfLoggedIn = ['Calendar', 'Zine', 'Shop'];
	const visibleOnMediumDesktop = [
		'Membership',
		'Calendar',
		'My events',
		'Zine',
		'Shop',
	];

	const isHomepage = router.pathname === '/';
	const isCartVisible = cart && cart.totalQuantity > 0;

	const { tier_level: tierLevel } = getSubscriptionMetadata(subscription);

	const headerScrollToggleTransitionTimeInMs = 120;
	const timeAfterWhichHeaderScrollToggleIfNoScrollActivityInMs = 500; // necessary in case browser that doesn't support scrollend (e.g. Safari)
	let mobileHeaderHeight = navigationMobileHeader.current?.clientHeight || 0;

	const toggleMobileHeader = () => {
		navigationContainer.current?.style.setProperty(
			'--transition-top',
			`${headerScrollToggleTransitionTimeInMs}ms ease-in-out top`,
		);

		if (window.scrollY > lastScrollY && window.scrollY > mobileHeaderHeight) {
			setShowMobileHeader(false);
		} else {
			setShowMobileHeader(true);
		}

		if (window.scrollY > mobileHeaderHeight) setLastScrollY(window.scrollY);

		setTimeout(() => {
			navigationContainer.current?.style.setProperty(
				'--transition-top',
				'0ms ease-in-out top',
			);
		}, headerScrollToggleTransitionTimeInMs);
	};

	const scrollMobileHeader = () => {
		clearTimeout(scrollTimeout);
		const relativeScrollY = window.scrollY - Math.max(0, lastScrollY);

		navigationContainer.current?.style.setProperty(
			'--scroll-offset',
			`${relativeScrollY}px`,
		);

		if (mobileHeaderHeight) {
			if (
				relativeScrollY > mobileHeaderHeight &&
				window.scrollY > mobileHeaderHeight
			) {
				if (showMobileHeader) {
					setShowMobileHeader(false);
				}
				setLastScrollY(window.scrollY);
			} else if (relativeScrollY < -mobileHeaderHeight) {
				if (!showMobileHeader) {
					setShowMobileHeader(true);
				}
				setLastScrollY(window.scrollY);
			}
		}

		scrollTimeout = setTimeout(() => {
			toggleMobileHeader();
		}, timeAfterWhichHeaderScrollToggleIfNoScrollActivityInMs);
	};

	useEffect(() => {
		if (navigationMobileHeader.current) {
			mobileHeaderHeight = navigationMobileHeader.current.clientHeight;
		}
	}, [navigationMobileHeader.current]);

	useEffect(() => {
		window.addEventListener('scroll', scrollMobileHeader);
		window.addEventListener('scrollend', toggleMobileHeader);

		return () => {
			window.removeEventListener('scroll', scrollMobileHeader);
			window.removeEventListener('scrollend', toggleMobileHeader);
		};
	}, [lastScrollY]);

	return (
		<nav
			aria-label="Main navigation"
			className={classNames(styles['navigation-container'], {
				[styles['navigation-container--home']]: isHomepage,
				[styles['navigation-container--hide-mobile-header']]: !showMobileHeader,
			})}
			ref={navigationContainer}
		>
			<div
				className={styles['navigation__mobile-header']}
				ref={navigationMobileHeader}
			>
				<Link
					aria-label="Homepage"
					className={classNames(
						styles.navigation__logo,
						styles['navigation__logo--mobile'],
					)}
					href="/"
				>
					<Svg id="logo" />
				</Link>
				<ul
					className={classNames(
						styles['navigation__anchor-list--mobile-header'],
						styles['navigation__anchor-list'],
						'font-t5-bold',
						'list-unstyled',
					)}
				>
					<li
						className={classNames(styles['navigation__anchor--membership'], {
							[styles['navigation__anchor--hide']]: authUser,
						})}
						key={membershipLink.url}
					>
						<MembershipAnchor url={membershipLink.url}>
							{membershipLink.title}
						</MembershipAnchor>
					</li>
					{navigationLinks
						.filter(({ url }) => url === myEventsLink.url)
						.map(({ url, title }) => {
							const isActive = getIsActiveUrl(router, url);
							return (
								<li className={styles.navigation__anchor} key={url}>
									<NavigationAnchor isActive={isActive} url={url}>
										{title}
									</NavigationAnchor>
								</li>
							);
						})}
					<li
						className={classNames(styles['navigation__account-button'], {
							[styles['navigation__account-button--logged_in']]: authUser,
							[styles['navigation__account-button--logged_in-tier-1']]:
								authUser && tierLevel === '1',
						})}
					>
						<NavigationAddOn showCart={false} />
					</li>
				</ul>
			</div>
			<div className={styles.navigation}>
				<Link
					aria-label="Homepage"
					className={classNames(
						styles.navigation__logo,
						styles['navigation__logo--desktop'],
					)}
					href="/"
				>
					<Svg id="logo" />
				</Link>
				<ul
					className={classNames(
						styles['navigation__anchor-list'],
						styles['navigation__anchor-list--desktop'],
						'font-t5-bold',
						'list-unstyled',
					)}
				>
					{navigationLinks.map(({ url, title }) => {
						const isActive = getIsActiveUrl(router, url);
						const isMembershipAnchor = title === 'Membership';

						return (
							<li
								className={classNames(styles.navigation__anchor, {
									[styles[
										'navigation__anchor--hide-before-xs-if-cart-visible'
									]]: isCartVisible && title !== 'Calendar',
									[styles['navigation__anchor--visible-on-mobile']]:
										visibleOnMobile.includes(title),
									[styles['navigation__anchor--visible-on-md']]: authUser
										? visibleOnSmallDesktopIfLoggedIn.includes(title)
										: visibleOnSmallDesktopIfNotLoggedIn.includes(title),
									[styles['navigation__anchor--visible-on-lg']]:
										visibleOnMediumDesktop.includes(title),
									[styles['navigation__anchor--membership']]:
										isMembershipAnchor,
									[styles['navigation__anchor--membership-logged-in']]:
										isMembershipAnchor && authUser,
								})}
								key={url}
							>
								{!isMembershipAnchor ? (
									<NavigationAnchor isActive={isActive} url={url}>
										{title}
									</NavigationAnchor>
								) : (
									<MembershipAnchor isActive={isActive} url={url}>
										{title}
									</MembershipAnchor>
								)}
							</li>
						);
					})}
					<li
						className={classNames(styles['navigation__account-button'], {
							[styles['navigation__account-button--hide']]: !authUser,
						})}
					>
						<NavigationAddOn
							hideLabelOnDesktop
							overrideDesktopColor={isHomepage ? 'sand' : 'black'}
						/>
					</li>
				</ul>
				{isCartVisible ? (
					<div
						className={classNames(styles.navigation__cart, {
							[styles['navigation__cart--homepage']]: isHomepage,
							[styles['navigation__cart--authenticated']]: !!authUser,
						})}
					>
						<Cart />
					</div>
				) : null}
				<button
					aria-label="Open fullscreen navigation"
					className={styles['navigation__open-fullscreen-button']}
					onClick={() => navigationFullscreenInstance?.show()}
					type="button"
				>
					<NavigationFullscreenOpenButtonContent
						hideBubble
						overrideMobileOpenButtonColor="sand"
					/>
				</button>
			</div>
		</nav>
	);
};
